import { generateUUID } from '~/utils/generateUUID'

function generateViewId() {
  const uuid = generateUUID()
  return `${uuid}`
}

export default function () {
  const viewId = generateViewId()

  if (process.client) {
    localStorage.setItem('viewId', viewId)
  }
}
