export default {
  data() {
    return {
      screenWidth: 0,
      throttledResizeHandler: null,
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth
    this.throttledResizeHandler = this.$throttle(this.handleResize, 750)
    window.addEventListener('resize', this.throttledResizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttledResizeHandler)
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth
    },
  },
}
