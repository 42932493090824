import { response } from '~/mocks/common'
import { IBanner, Types } from 'types/DTO/banners'

const _bannerSmall = (id: number): IBanner => ({
  id,
  type: Types.small,
  title: '',
  description: '',
  images: {
    raw: '/small-banner-placeholder.png',
    thumbnail: '/small-banner-placeholder.png',
    medium: '/small-banner-placeholder.png',
  },
  link: {
    external: false,
    link: '#',
  },
  is_active: true,
  location: '',
})

const _bannerLarge = (id: number): IBanner => ({
  id,
  type: Types.large,
  title: '🤕 От болей',
  description: '50 препаратов, помогающих от головы, живота и зуба',
  link: {
    external: false,
    link: '#',
  },
  is_active: true,
  location: '',
})

const smallBanners: IBanner[] = []
const largeBanners: IBanner[] = []

for (let i = 0; i < 7; i++) {
  smallBanners.push(_bannerSmall(10 + i))
  if (i < 4) {
    largeBanners.push(_bannerLarge(20 + i))
  }
}

const smallBannerResponse = response(smallBanners)
const largeBannerResponse = response(largeBanners)
const all = response([...smallBanners, ...largeBanners])

export { smallBannerResponse, largeBannerResponse, all }
