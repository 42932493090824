import { render, staticRenderFns } from "./cart-layout.vue?vue&type=template&id=1c4aa985"
import script from "./cart-layout.vue?vue&type=script&lang=js"
export * from "./cart-layout.vue?vue&type=script&lang=js"
import style0 from "./cart-layout.vue?vue&type=style&index=0&id=1c4aa985&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/src/components/atoms/Button.vue').default,RPHToastContainer: require('/usr/src/app/src/components/v2/organisms/RPHToastContainer/index.vue').default})
