export default function filterPrice(val) {
  if (val || parseInt(val) === 0) {
    if (typeof parseInt(val) === 'number') {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '')
    } else {
      return ''
    }
  } else {
    return ''
  }
}
