
import BackIcon from '~/assets/icons/back-arrow.svg?inline'

export default {
  name: 'cart-layout',
  components: {
    BackIcon,
    RPHToastContainer: () => import('rph/organisms/RPHToastContainer/index.vue'),
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
