import { IEntityReviewFull } from 'types/DTO/reviews'

const _reviews = (id: number): IEntityReviewFull => ({
  id,
  user: {
    name: 'Дмитрий',
  },
  rate: 4,
  content:
    'У вас отличный сервис, передайте привет девушке консультанту: она большой профессионал у вас!',
  created_at: '15 мая 2020',
  replies: [
    {
      id,
      user: {
        name: 'Дмитрий',
      },
      rate: 5,
      content:
        'Ага, мне тоже понравилось! Хм... мой коммент выглядит как показ функции ответа в отзыве. Это и есть моя функция на этом макете? Чет грустно :(',
      created_at: '15 мая 2020',
    },
  ],
})

const reviews: IEntityReviewFull[] = []

for (let i = 0; i < 4; i++) {
  reviews.push(_reviews(i))
}

const res = {
  reviews,
}

export { res }
