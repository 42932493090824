// @ts-nocheck
export default function ({ req, $axios }) {
  if (process.client || process.server) {
    const cookieSource = process.server ? req.headers.cookie : process.client ? document.cookie : ''
    if (cookieSource) {
      const cookies = parseCookies(cookieSource)
      if (cookies['mindboxDeviceUUID']) {
        $axios.setHeader('X-deviceuuid', cookies['mindboxDeviceUUID'])
      }
    }
  }
}

// Функция для парсинга cookies
function parseCookies(cookieString) {
  const cookies = {}
  if (cookieString) {
    cookieString.split(';').forEach(function (cookie) {
      const parts = cookie.split('=')
      const key = parts.shift().trim()
      cookies[key] = decodeURIComponent(parts.join('='))
    })
  }
  return cookies
}
