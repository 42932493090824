import { Context } from '@nuxt/types'

// @ts-ignore
export default function ({ redirect, route }: Context) {
  if (!route.query.page) return
  if (route.query.page === '1') {
    const { page, ...queryWithoutPage } = route.query
    return redirect(301, route.path, queryWithoutPage)
  }
}
