
import ErrorPage from 'rph/templates/RPHErrorPage/index.vue'

export default {
  name: 'Error',
  components: { ErrorPage },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    await Promise.all([
      this.$store.dispatch('rph-products/getProducts', {
        params: {
          expand: 'annotation,seo,extra,category,prices,images,producers',
          'filter[is_season]': 1,
          'per-page': 5,
        },
      }),
      this.$store.dispatch('rph-products/getNearestProducts', {
        params: {
          expand: 'annotation,specification,images,prices,seo,category,extra,producers',
          'filter[is_special]': 1,
          'filter[sort_stock]': 1,
          'per-page': 10,
        },
      }),
      this.$store.dispatch('rph-banners/getSmallBanners'),
      this.$store.dispatch('rph-banners/getLargeBanners'),
    ])
  },
  fetchOnServer: false,
  computed: {
    products() {
      return this.$store.getters['rph-products/nearestProducts']
    },
  },
}
